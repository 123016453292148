import * as React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import AppBarLayout from '../../Layout/AppBar'
import PricatFinder from './PricatFinder/PricatFinder';

export default function App() {


  return (
  <Router>
    <div>
      <div>
        <AppBarLayout />
      </div>
      
      <Switch>
        <Route path="/pricat_finder">
          <PricatFinder />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  </Router>
  );
};

function Home() {
  return <h2>Home</h2>;
}