import React from 'react';
import {
    Link as RouterLink
  } from "react-router-dom";

import {AppBar, Link, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Image from 'mui-image'


const pages = [
    {'title': 'Pricat Finder', 'url': '/pricat_finder'}
  ]

function AppBarLayout() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    return ( 
        <AppBar position="static">
            <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Link component={RouterLink} to="/" sx={{ flexGrow: 1 }}>
                    <Image src="/logo.svg"
                      height={'32px'} width={''}
                      fit={'fill'} duration={500} easing={'ease-in'}
                      showLoading={ true } errorIcon={ true } shift={ null }
                      distance={'100px'} shiftDuration={900} bgColor=""
                    />
                  </Link>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    {pages.map((page) => (
                        <MenuItem key={page} component={RouterLink} to={page.url}>
                            <Typography textAlign="center">{page.title}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
                </Box>
                <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                >
                Tréndico Apps
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <Button
                        key={page}
                        component={RouterLink} to={page.url}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                        {page.title}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
            </Container>
        </AppBar> 
    );
}

export default AppBarLayout;