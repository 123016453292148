import React from 'react';
import {Paper, Chip, Stack, Divider, Tooltip} from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import WcIcon from '@mui/icons-material/Wc';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import CategoryIcon from '@mui/icons-material/Category';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

export default function ProductGrid(props) {
    return (
      <Paper sx={{ p: 2, margin: 'auto', flexGrow: 1 }}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                
                <Box sx={{ m: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {props.data.pricatLog}
                  </Typography>
                </Box>
  
                <Box sx={{ m: 1 }}>
                  <Typography gutterBottom variant="body1">
                    Marca:
                  </Typography>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {props.data.t_brand}
                  </Typography>
                </Box>
  
                <Box sx={{ m: 1 }}>
                  <Typography gutterBottom variant="body1">
                    Referencia:
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {props.data.reference}
                  </Typography>
                </Box>
                
                <Divider variant="middle" />
                <Box sx={{ m: 1 }}>
                  <Typography gutterBottom variant="body1">
                    Modelo:
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {props.data.product_name}
                  </Typography>
                </Box>
  
                <Divider variant="middle" />
                <Box sx={{ m: 1 }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    <Tooltip title="Género"><Chip icon={<WcIcon/>} label={props.data.t_gender} color="secondary"/></Tooltip>
                    <Tooltip title="División"><Chip icon={<CheckroomIcon/>} label={props.data.t_division} color="primary"/></Tooltip>
                    <Tooltip title="Categoría"><Chip icon={<CategoryIcon/>} label={props.data.t_category} color="primary"/></Tooltip>
                    <Tooltip title="Subcategoría"><Chip icon={<SubdirectoryArrowRightIcon/>} label={props.data.t_sub_category} color="primary"/></Tooltip>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
      </Paper>
    );
  }