import axios from "axios";

const API_HOST = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:4000';
const API_URL = API_HOST + "/api/pricat/";

class PricatFinderService {
  getList(page, orderBy, search) {
    return axios.get(API_URL + "list", {
      params: {
        page,
        orderBy,
        search
      }
    });
  }

  getOnePricat( search ) {
    return axios.get(API_URL + "search", {

      params: {
        search
      }
    });
  }
}

export default new PricatFinderService();