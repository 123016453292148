import React, { useState } from 'react';
import {Avatar, Paper, CssBaseline, Link, Collapse, Alert, IconButton, InputBase, FormHelperText, Skeleton, AvatarGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import PricatFinderService from '../../../Services/index';
import PricatFinderCard from './PricatFinderCard';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://trendico.es/">
        Tréndico Group SL
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const theme = createTheme();

export default function App() {

  // Declare all useState here:
  const [product, setProduct] = useState(null);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [barcode, setSearchValue] = useState(null);
  
  const onKeyPressSearch = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event)
    }
  }

  const onChangeBarcode = (event) => {
    if (event.target.value) {
      setSearchValue(event.target.value)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePricat(barcode);
  };

  const handlePricat = async (search) => {
    setLoadingProduct(true)
    PricatFinderService.getOnePricat(
        search
    ).then(
        response => {
          setProduct( response.data )
          setLoadingProduct(false)
        },
        error => {
          const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          setProduct(false)
          setLoadingProduct(false)
          setAlertStatus(true)
          setAlertContent(resMessage)
        }
    );
};

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AvatarGroup max={4}>
            <Avatar alt="Tréndico Apps" src="/logo192.png" />
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <SearchIcon />
            </Avatar>
          </AvatarGroup>
          
          <Typography component="h1" variant="h5">
            Buscador de Pricat
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Buscar Pricat"
                inputProps={{ 'aria-label': 'Buscar Pricat con Referencia o Codigo de barras' }}
                onChange={onChangeBarcode}
                onKeyPress={onKeyPressSearch}
                maxWidth
              />
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <FormHelperText id="component-helper-text">
              Buscar Pricat con Referencia o Codigo de barras
            </FormHelperText>
          </Box>
          <Collapse in={alertStatus}>
            <Alert severity="error" sx={{ mt: 3, mb: 3}} 
              action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {setAlertStatus(false)}}
                    ><CloseIcon fontSize="inherit" />
                    </IconButton>
              }>{alertContent}</Alert>
          </Collapse>
          <Box sx={{ display: 'flex' }}>
          { loadingProduct ? (
              <Box>
                <Skeleton variant="rectangular" width={210} height={118} />
                <Skeleton />
              </Box>
            ) : (
              <Box>
                {product &&
                  <PricatFinderCard data={product} sx={{ mt: 8, mb: 4 }} />
                }
              </Box>
            )
          }
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}